import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userlogin" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { style: {"margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_switch = _resolveComponent("van-switch")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_image, {
        width: "100%",
        fit: "fill",
        src: require('../assets/images/header3.png')
      }, null, 8, ["src"])
    ]),
    _createVNode(_component_van_cell_group, { title: "用户登录" }, {
      default: _withCtx(() => [
        _createVNode(_component_van_form, {
          ref: "refUserForm",
          onSubmit: _ctx.onLogin
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              modelValue: _ctx.userForm.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userForm.username) = $event)),
              label: "用户名",
              placeholder: "请输入用户名",
              required: "",
              rules: [{ required: true, message: '请输入用户名' }],
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.userForm.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userForm.password) = $event)),
              label: "密码",
              type: "password",
              placeholder: "请输入密码",
              required: "",
              rules: [{ required: true, message: '请输入密码' }],
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              name: "switch",
              label: "下次自动登录",
              "input-align": "right"
            }, {
              input: _withCtx(() => [
                _createVNode(_component_van_switch, {
                  modelValue: _ctx.userForm.isAutoLogin,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userForm.isAutoLogin) = $event)),
                  size: "20"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_van_button, {
                round: "",
                block: "",
                type: "primary",
                onClick: _ctx.doLogin
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 登录 ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    })
  ]))
}