
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import { Encrypt } from '@/middlewares/crypto';

export default class UserLogin extends Vue {
  @Action("setToken") private setToken: any;
  @Action("setUserInfo") private setUserInfo: any;
  @Action("setUserCurDept") private setUserCurDept: any;
  @Action("setUserDepts") private setUserDepts: any;
  @Action("setAutoLogin") private setAutoLogin: any;
  private userForm: any = {
    username: "",
    password: "",
    isAutoLogin: false,
  };

  public created() {
    if (
      this.$store.state.autoLogin &&
      this.$store.state.autoLogin.isAutoLogin
    ) {
      this.userForm.username = this.$store.state.autoLogin.username;
      this.userForm.password = this.$store.state.autoLogin.password;
      this.userForm.isAutoLogin = this.$store.state.autoLogin.isAutoLogin;
    }
  }

  public mounted() {
    if (this.userForm.isAutoLogin) {
      this.onLogin();
    }
  }

  //提交
  private async doLogin() {
    const ref: any = this.$refs.refUserForm;
    ref.submit();
  }

  private async onLogin() {
    this.$toast.loading({
      message: "登录中...",
      forbidClick: true,
    });
    const url = "/public/user/loginH5";
    this.axios
      .post(url, {
        username: this.userForm.username,
        password: Encrypt(this.userForm.password)
      })
      .then(async (res: any) => {
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          this.setToken(res.data.token);
          const user: any = {
            id: res.data.user.id,
            realname: res.data.user.realname,
            tel: res.data.user.tel,
            username: res.data.user.username,
            orgId: res.data.user.orgId,
            orgName: res.data.user.organization
              ? res.data.user.organization.name
              : "",
            orgCode: res.data.user.organization
              ? res.data.user.organization.code
              : "",
            orgType: res.data.user.organization
              ? res.data.user.organization.type
              : "",
            hisNo: res.data.user.hisNo,
            lisNo: res.data.user.lisNo,
            deptId: res.data.user.deptId,
            initializeFlag: res.data.user.initializeFlag,
            token: res.data.token,
          };
          this.setUserInfo(user);
          if (this.userForm.isAutoLogin) {
            this.setAutoLogin({
              username: this.userForm.username,
              password: this.userForm.password,
              isAutoLogin: true,
            });
          } else {
            this.setAutoLogin({
              username: "",
              password: "",
              isAutoLogin: false,
            });
          }
          this.getDept();
        }
      })
      .catch((err: any) => {
        console.log(err, "提示");
        this.$toast.clear();
      });
  }

  private getDept() {
    const url = "/api/user/findUserDepts";
    const userId = this.$store.state.userInfo.id;
    this.axios
      .get(url, { params: { userId } })
      .then((res: any) => {
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else if (res.data.userDepts.length === 0) {
          this.$toast.fail("请联系系统管理员，添加部门信息");
        } else {
          const dept = res.data.userDepts[0].Department;
          const obj = {
            deptId: dept ? dept.id : "",
            deptName: dept ? dept.name : "",
            deptCode: dept ? dept.code : "",
          };
          this.setUserCurDept(obj);
          this.setUserDepts(res.data.userDepts.map((p: any) => p.Department));
          this.$toast.clear();
          // this.$router.push("/userhome");
          location.href = "/userhome";
        }
      })
      .catch((err) => {
        console.log(err, "提示");
      });
  }
}
