/**
 * 对字符串进行加密
 */
// let CryptoJS = require('crypto-js'); // 引入AES源码js
import CryptoJS from 'crypto-js';

/*
  * 对字符串进行加密
  * @param {String}   word  需要加密的字符串
  * @param {String}   keyStr  对字符串加密的秘钥，注意这里长度必须是8的整数倍，否则解密无效
  * @return {String}   加密的密文
  * */
function Encrypt(word:any, keyStr? :any) { // 加密
  keyStr = keyStr ? keyStr : '1234567890ABCDEF';
  const key = CryptoJS.enc.Utf8.parse(keyStr);
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
/*
* 对加密之后的密文进行解密
* @param {String}   word  需要解密的字符串
* @param {String}   keyStr  对密码加密的秘钥
* @return {String}   解密的明文
* */
function Decrypt(word:any, keyStr? :any) { // 解密
  keyStr = keyStr ? keyStr : '1234567890ABCDEF';
  const key = CryptoJS.enc.Utf8.parse(keyStr);
  const decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export { Encrypt, Decrypt };
